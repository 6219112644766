// Author: 劉喆 John Liu
// License: GNU General Public License Version 3 (GPLv3)

import React, { useLayoutEffect, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCurrentMode, reindexCards, buttonColors, toggleRandomState, toggleWriteState,
        updateScrollPosition, setCurrentSet, shuffleAndModifyKeys, toggleShowSide1,
        setCurrentSubMenu, initializeSpeech, setSpeakerState } from './actions';
import { persistor } from './store';

import "./AppStyles.css";
import "./MenuStyles.css";

import { SpeakerXMarkIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { HomeIcon, StarIcon as StarFillIcon, 
         SpeakerWaveIcon, ArrowsRightLeftIcon} from '@heroicons/react/24/solid'; 

import Cards from "./Cards";

export default function MainMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userList = useSelector((state: any) => state.app.userList);

  const randomState = useSelector((state: any) => state.app.randomState);
  const writeState = useSelector((state: any) => state.app.writeState);
  const scrollPosition = useSelector((state: any) => state.app.scrollPosition);
  const speaker = useSelector((state: any) => state.app.speakerState) ?? true;
  const flashcarddata = useSelector((state: Record<string, any>) => state.flashcard.flashcarddata);

  const currentMode = useSelector((state: any) => state.app.currentMode); // Get current mode from state
  const modes = useSelector((state: any) => state.app.modes); // Get modes array from state
  const showSide1 = useSelector((state: any) => state.app.cardsVisibility);

  // useLayoutEffect activates before the next component renders
  useLayoutEffect(() => {
  	window.scrollTo(0, scrollPosition);
    return () => {
      dispatch(updateScrollPosition(window.scrollY));
    };
  }, [scrollPosition, dispatch]);

  useEffect(() => {
    if (currentMode === "寫") {
      if (!writeState) {
        dispatch(toggleWriteState());
      }
    } else if (currentMode === "練") {    
      if (writeState) {
        dispatch(toggleWriteState());
      }
      if (showSide1) {
        dispatch(toggleShowSide1());
      }
    } else {
      if (writeState) {
        dispatch(toggleWriteState());
      }
      if (!showSide1) {
        dispatch(toggleShowSide1());
      }        
    }
  }, [currentMode, writeState]);  // Watch these states for changes


  const selectUserList = () => {
    initializeSpeech();

    let carddata = randomState ? shuffleAndModifyKeys(userList) : reindexCards(userList);
    dispatch(setCurrentSet(carddata));
    dispatch(setCurrentSubMenu("MainMenu"));    
    navigate("/cards");
  };

  const handleShuffleChange = () => {
    dispatch(toggleRandomState());
  };

  const handleWriteChange = () => {
    dispatch(toggleWriteState());
  };

  function returnHome() {
    navigate("/");
  }

	const handlePurgeLocalStorage = () => {
	  persistor.purge();
	  window.location.reload();
	};

  const subMenuOptions = Object.keys(flashcarddata);
  // calculate button height size based on number of options
  const subMenuButtonHeight = Math.max( 100, 300 / Math.ceil( subMenuOptions.length / 2 ) );

  // calculate button font size based on max length of text of options
  const subMenuKeyLengths = Object.keys(flashcarddata).map(key => key.length);
  const maxLength = Math.max(...subMenuKeyLengths);
  const subMenuFontSize = (maxLength < 4) ? "30px" : (maxLength == 4) ? "24px" : (maxLength == 5) ? "21px" : "18px";

  // // calculate different font sizes for each button
  // const subMenuFontSize = (label: any) => {
  //   const len = label.length;
  //   return (len < 4) ? "30px" : (len == 4) ? "24px" : (len == 5) ? "20px" : "18px";
  // }

  const handleSubMenuClick = (setName: any) => {
    initializeSpeech();
    dispatch(setCurrentSubMenu(setName));
    navigate('/submenu')
  };

  function handleSpeakerClick(event:any) {
    event.stopPropagation();  //prevent card from being flipped
    const newSpeakerState = !speaker;  // Toggle the state
    dispatch(setSpeakerState(newSpeakerState));
  }

  const handleModeChange = () => {
    dispatch(setCurrentMode());
  };


  return (
  	<div className="mainmenu">
	  	<div className="menu">
        <button onClick={returnHome} className="appNameButton" style={{backgroundColor: "none"}}>練習國字</button>
      </div>

	  	<div className="topbody">
        <button onClick={handleModeChange} className={`mode-toggle-button ${currentMode}`}>
          <span className="text">{currentMode}</span> {/* Display current mode */}
        </button>

		   	<button onClick={handleShuffleChange} className={`toggle-button ${randomState ? '隨機順序' : '正常秩序'}`}>
          <ArrowsRightLeftIcon style={{ width: '30px', height: '30px', color: 'white' }} />
      	</button>

        <button onClick={handleSpeakerClick} className="speakerButton">
          {speaker ? <SpeakerWaveIcon style={{ width: '30px', height: '30px', color: 'white' }} /> : <SpeakerXMarkIcon style={{ width: '30px', height: '30px', color: 'white', opacity: '30%' }} />}
        </button>

		   	<button onClick={selectUserList} className="userListButton">
          <StarFillIcon style={{ width: '30px', height: '30px', color: 'yellow' }} />
		   	</button>
      </div>

      <div className="basemenu">
        {subMenuOptions.map((option, index) => (
          <button key={index} 
          				onClick={() => handleSubMenuClick(option)}
                  style={{ backgroundColor: buttonColors[index % buttonColors.length],
                           height: subMenuButtonHeight,
                           fontSize: subMenuFontSize  }}>
{/*                           fontSize: subMenuFontSize(option)  }}> */}
            {option}
          </button>
        ))}
      </div>

	   	<div className="footer">
		  	<small>
		  		秀朗國小補校
		  		<br/>
		  		App創作者：劉喆 2024
		  	</small>
			</div>
		</div>
  )
}
