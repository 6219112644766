// Author: 劉喆 John Liu
// License: GNU General Public License Version 3 (GPLv3)

import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, persistor } from './store';

import { reindexCards, buttonColors, toggleRandomState, toggleWriteState,
         setCurrentMode, setCurrentSet, shuffleAndModifyKeys, toggleShowSide1,
         initializeSpeech, setCurrentSubMenu,
         setSpeakerState } from './actions';

import "./AppStyles.css";
import "./MenuStyles.css";

import { SpeakerXMarkIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { HomeIcon, StarIcon as StarFillIcon, 
         SpeakerWaveIcon, ArrowsRightLeftIcon} from '@heroicons/react/24/solid'; 


// export default function SubMenu({ setOptions }) {
export default function SubMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const flashcarddata = useSelector((state: Record<string, any>) => state.flashcard.flashcarddata);

  const userList = useSelector((state: RootState) => state.app.userList);
  const randomState = useSelector((state: RootState) => state.app.randomState);
  const writeState = useSelector((state: any) => state.app.writeState);
  const currentSubMenu = useSelector((state: RootState) => state.app.currentSubMenu);
  const speaker = useSelector((state: any) => state.app.speakerState) ?? true;
  const setName = currentSubMenu;

  const currentMode = useSelector((state: any) => state.app.currentMode); // Get current mode from state
  const modes = useSelector((state: any) => state.app.modes); // Get modes array from state
  const showSide1 = useSelector((state: any) => state.app.cardsVisibility);

  useEffect(() => {
    if (setName == null) {
      navigate("/");
    }
  }, [setName, navigate]);


  useEffect(() => {
    if (currentMode === "寫") {
      if (!writeState) {
        dispatch(toggleWriteState());
      }
    } else if (currentMode === "練") {    
      if (writeState) {
        dispatch(toggleWriteState());
      }
      if (showSide1) {
        dispatch(toggleShowSide1());
      }
    } else {
      if (writeState) {
        dispatch(toggleWriteState());
      }
      if (!showSide1) {
        dispatch(toggleShowSide1());
      }        
    }
  }, [currentMode, writeState]);  // Watch these states for changes


//-----------------------------------------
//
//  Select Set
//
//-----------------------------------------

  const selectSet = (event: React.MouseEvent<HTMLButtonElement>, subsetName: string) => {
    initializeSpeech();
    // const dataset = (flashcarddata[setName as keyof typeof flashcarddata] as any)[subsetName];
    let dataset;

    // Calculate the vertical click position relative to the button
    const button = event.currentTarget;
    // const buttonHeight = button.offsetHeight;
    const buttonWidth = button.offsetWidth;

    // const clickPosition = event.clientY - button.getBoundingClientRect().top; // Position within the button
    const clickPosition = event.clientX - button.getBoundingClientRect().left;

    if (clickPosition > buttonWidth / 2) {
      
      // Accumulate all subsets up to the clicked one
      const allSubsets = Object.keys(flashcarddata[setName as keyof typeof flashcarddata]);
      const clickedSubsetIndex = allSubsets.indexOf(subsetName);
      const cumulativeSubsets = allSubsets.slice(0, clickedSubsetIndex + 1);

      // Combine datasets of the subsets up to the clicked one
      dataset = cumulativeSubsets.reduce<any[]>((acc, subset) => {
        // Add the current subset data to the accumulator
        return [...acc, ...(flashcarddata[setName][subset] || [])];
      }, []);
      
    } else {
      // If clicked in the top half, just use the clicked subset
      dataset = flashcarddata[setName][subsetName] || [];
    }

    if (dataset.length > 0) {
      const carddata = randomState ? shuffleAndModifyKeys(dataset) : dataset;
      dispatch(setCurrentSet(carddata));
      navigate("/cards");
    } else {
      console.error(`Invalid set name: ${subsetName}`);
    }

  };

  const selectUserList = () => {
    initializeSpeech();

    const carddata = randomState ? shuffleAndModifyKeys(userList) : reindexCards(userList);
    dispatch(setCurrentSet(carddata));
//    dispatch(setCurrentSubMenu("MainMenu"));  // remove this so up navigates back to submenu
    navigate("/cards");
  };

  const handleShuffleChange = () => {
    dispatch(toggleRandomState());
  };

  const handleWriteChange = () => {
    dispatch(toggleWriteState());
  };

  function handleSpeakerClick(event:any) {
    event.stopPropagation();  //prevent card from being flipped
    const newSpeakerState = !speaker;  // Toggle the state
    dispatch(setSpeakerState(newSpeakerState));
  }

  function returnHome() {
    navigate("/");
  }

  const handleModeChange = () => {
    dispatch(setCurrentMode());
  };

  function darkenColor(color:string, percentage:number): string {
    // Function to darken a single color component
    const darken = (value:number, percentage:number) => Math.max(0, Math.min(255, value - (value * percentage) / 100));

    let hex = color.slice(1);

    // Handle shorthand hex (e.g., #ABC => #AABBCC)
    if (hex.length === 3) {
      hex = hex.split('').map((h) => h + h).join('');
    }

    // Extract RGB components from the hex string
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);

    // Darken each component
    const newR = darken(r, percentage);
    const newG = darken(g, percentage);
    const newB = darken(b, percentage);

    // Convert the new RGB values to hex and round to the nearest integer
    const toHex = (value:number) => Math.round(value).toString(16).padStart(2, '0');

    // Return the hex color string
    return `#${toHex(newR)}${toHex(newG)}${toHex(newB)}`;
  }



  return (
    <div className="submenu">
      <div className="menu">
        <button onClick={returnHome} className="appNameButton" style={{backgroundColor: "saddlebrown", boxShadow: "none" }}>
          <HomeIcon style={{ width: '30px', height: '30px', color: 'white' }} />
        </button>
      </div>

      <div className="topbody">
{/*
        <button onClick={handleWriteChange} className={`write-toggle-button ${writeState ? 'write' : 'nowrite'}`}>
          <PencilSquareIcon style={{ width: '30px', height: '30px', color: 'white' }} />
        </button>
*/}
        <button onClick={handleModeChange} className={`mode-toggle-button ${currentMode}`}>
          <span className="text">{currentMode}</span> {/* Display current mode */}
        </button>

        <button onClick={handleShuffleChange} className={`toggle-button ${randomState ? '隨機順序' : '正常秩序'}`}>
          <ArrowsRightLeftIcon style={{ width: '30px', height: '30px', color: 'white' }} />
        </button>
        <button onClick={handleSpeakerClick} className="speakerButton">
          {speaker ? <SpeakerWaveIcon style={{ width: '30px', height: '30px', color: 'white' }} /> : <SpeakerXMarkIcon style={{ width: '30px', height: '30px', color: 'white', opacity: '30%' }} />}
        </button>
        <button onClick={selectUserList} className="userListButton">
          <StarFillIcon style={{ width: '30px', height: '30px', color: 'yellow' }} />
        </button>
      </div>

      <div className="nav3fr">
        {setName && flashcarddata[setName as keyof typeof flashcarddata] && 
          Object.keys(flashcarddata[setName as keyof typeof flashcarddata]).map((subsetName,index) => {
            const topColor = buttonColors[index % buttonColors.length];
            const bottomColor = darkenColor(topColor, 15);  // Darken the color for the bottom half

            return (
              <button
                key={subsetName}
                onClick={(event) => selectSet(event, subsetName)}
                style={{ background: `linear-gradient(to right, ${topColor} 50%, ${bottomColor} 50%)`}}
              >
                {subsetName}
              </button>
            );
          })
        }
      </div>

    </div>
  );
}
