// Author: 劉喆 John Liu
// License: GNU General Public License Version 3 (GPLv3)

import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCardSide, initializeSpeech, setSpeakerState } from "./actions"

import "./AppStyles.css";
import "./CardsStyles.css";
import Card from "./Card";

import iconHome from "./assets/img/home.png"
import iconNext from "./assets/img/next.png"
import iconPrev from "./assets/img/prev.png"
import iconUp from "./assets/img/up.png"
import iconPlay from "./assets/img/play.png"
import iconStop from "./assets/img/stop.png"


const ROOT = ""

export default function Cards() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentSet = useSelector((state: any) => state.app.currentSet);
  const randomState = useSelector((state: any) => state.app.randomState);
  const currentSubMenu = useSelector((state: any) => state.app.currentSubMenu);
  let speaker = useSelector((state: any) => state.app.speaker);
  let side = useSelector((state: any) => state.app.cardSide);
  const writeState = useSelector((state: any) => state.app.writeState);
  console.log("cards writeState",writeState);

  const [current, setCurrent] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0); // State for side 2 elapsed time
  const [intervalTime, setIntervalTime] = useState(100); // Default interval time
  const [maxTime, setMaxTime] = useState(5000);

  const currentMode = useSelector((state: any) => state.app.currentMode); // Get current mode from state
  const modes = useSelector((state: any) => state.app.modes); // Get modes array from state

  const cards = currentSet.map((card: any, isPlaying: boolean) => {
    return <Card card={card} key={card.id} isPlaying={isPlaying} />;
  });

  const loading = <div className="loading">空卡文件...</div>;

  
  // navigation in cards
  function previousCard() {
    setCurrent(current - 1);
    dispatch(setCardSide(false));
  }
  function nextCard() {
    setCurrent(current + 1);
    dispatch(setCardSide(false));
  }

  function gotoSubMenu() {
    const synth = window.speechSynthesis;
    synth.cancel();
    dispatch(setCardSide(false));
    (currentSubMenu == "MainMenu") ? navigate(`/MainMenu`) : navigate(`/SubMenu`);
  }

  function returnHome() {
    dispatch(setCardSide(false));
    navigate("/");
  }

  function togglePlay() {
    setIsPlaying(!isPlaying);
    dispatch(setCardSide(false));
  };


  // Increase speed
  function increaseSpeed() {
    if (maxTime >= 1500) {
      setMaxTime(maxTime - 500);
    }
  }

  // Decrease speed
  function decreaseSpeed() {
    setMaxTime(maxTime + 500);
  }

  useEffect(() => {
    if (currentMode === "寫") {
      dispatch(setSpeakerState(true));
    } else if (currentMode === "練") {    
      dispatch(setSpeakerState(true));
    } else {

    }
  }, []);  // Watch these states for changes


  useEffect(() => {
    let interval:any = null;
    let timeElapsed = 0; // Track time elapsed for current card
    let showingSide1:boolean = true; // Flag to track which side of the card is being shown

    if (isPlaying) {
      interval = setInterval(() => {
        timeElapsed += intervalTime;
        timeElapsed = timeElapsed > maxTime ? maxTime : timeElapsed;
        setProgress(100*timeElapsed/maxTime);

        if (showingSide1) {
          if (timeElapsed >= maxTime) {
            dispatch(setCardSide(true));
            showingSide1 = false;
            timeElapsed = 0;
          }
        } else {
          if (timeElapsed >= maxTime) {
            if (current < currentSet.length - 1) {
              setCurrent(current => current + 1);
              dispatch(setCardSide(false));
              showingSide1 = true;
              timeElapsed = 0;
            } else {
              setIsPlaying(false);
              clearInterval(interval);
            }
          }
        }

      }, intervalTime);

    } else {
      setProgress(0);
    }
    return () => clearInterval(interval);
  }, [isPlaying, current, currentSet, dispatch, intervalTime]);

  return (
    <div>

      {/* number of cards */}
      {currentSet && currentSet.length > 0 ? (
        <div className="cardNumber">
          卡 {current + 1} 共 {currentSet.length} 張
        </div>
      ) : (
        ""
      )}

      {/* render cards */}
      {currentSet && currentSet.length > 0 ? cards[current] : loading}
      {/* /render cards */}

      {/* render nav buttons */}
      <div className="nav2fr">
        {current > 0 ? (
          <button onClick={previousCard}>
            <img src={iconPrev} alt="上張卡" />
          </button>
        ) : (
          <button className="disabled" disabled>
            <img src={iconPrev} alt="上張卡" />
          </button>
        )}
        {current < currentSet.length - 1 ? (
          <button onClick={nextCard}>
            <img src={iconNext} alt="下張卡" />
          </button>
        ) : (
          <button className="disabled" disabled>
            <img src={iconNext} alt="下張卡" />
          </button>
        )}
        {/* /render nav buttons */}
      </div>
      <div className="nav2fr">
        <button className='up' onClick={gotoSubMenu}>
            <img src={iconUp} alt="上個選單" />
        </button>
{/*        <button className='home' onClick={returnHome}>
            <img src={iconHome} alt="返回首頁" />
        </button>
*/}
        {!writeState && !isPlaying ? 
          (
            <button className='play' onClick={togglePlay}>
              <img src={iconPlay} alt="走" />
            </button>
          ) : !writeState && (
            <button className='stop' onClick={togglePlay}>
              <img src={iconStop} alt="停" />
            </button>
          )
        }

      </div>

      { isPlaying ? (
          <div>
            <div className="progress-bar-container">
              <div className="progress-bar" style={{ width: `${progress}%` }}></div>
              <div className="progress-number">{maxTime/1000}秒</div>
            </div>
            <div className="speed-button-container">
              <button className="speedup" onClick={increaseSpeed}>快</button>
              <button className="speeddown" onClick={decreaseSpeed}>慢</button>
            </div>
          </div>
        ) : ( <div></div> )
      }

    </div>
  );
}
